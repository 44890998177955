
let auth = {
    verifySms: 'zyd-common/tools/sendSmsMessage',
    basesysrole: 'zyd-user/basesysrole/list',
    queryRoleUserList: 'zyd-user/basesysrole/queryRoleUserList',
    basesysroleSave: 'zyd-user/basesysrole/save',
    basesysroleBatchRemove: 'zyd-user/basesysrole/batchRemove',
    getMenus: 'zyd-user/sysuser/getMenus',  // 用户权限查询接口
    getById: 'zyd-user/sysrolemenu/getById',  // 查询角色权限
    sysroleList: 'zyd-user/sysrole/list',  // 角色列表
    sysnewmenuList: 'zyd-user/sysnewmenu/list',  // 查询菜单列表
    sysnewmenuRemoveById: 'zyd-user/sysnewmenu/removeById',  // 删除菜单
    sysnewmenuUpdateById: 'zyd-user/sysnewmenu/updateById',  // 修改菜单信息
    sysnewmenuSave: 'zyd-user/sysnewmenu/save',  // 新增菜单信息
    sysrolemenuSave: 'zyd-user/sysrolemenu/save',  // 角色授权保存
    removeById: 'zyd-user/sysuserrole/removeById',  // 移除角色用户
    sysroleGetById: 'zyd-user/sysrole/getById',  // 查询角色信息
    sysroleUpdateById: 'zyd-user/sysrole/updateById',  // 修改角色信息
    sysroleRemoveById: 'zyd-user/sysrole/removeById',  // 删除角色
    sysroleSave: 'zyd-user/sysrole/save',  // 新增角色
    sysuserQueryAllList: 'zyd-user/sysuser/queryUserList',  // 查询所有用户
    sysuserQueryRoleUserList: 'zyd-user/sysuserrole/queryRoleUserList',  // 查询非角色用户
    getByIdAllUser: 'zyd-user/sysuserrole/getByIdAllUser',  // 根据角色查询用户列表
    sysuserroleSave: 'zyd-user/sysuserrole/save',  // 批量关联角色用户
    login: "zyd-user/login/login", // 登录
    loginout: "zyd-user/login/loginout", // 登出日志记录
    findList: "zyd-common/logmannger/findList",
    sysuserUpdateById: "zyd-user/sysuser/updateById", // 修改用户信息
    sysuserRemove: "zyd-user/sysuser/remove", // 删除用户信息
    sysuserSave: "zyd-user/sysuser/save", // 保存用户
    GetUserById: "zyd-user/sysuser/getById",
    //字典管理
    dictTypeList: "zyd-common/dictType/queryAllByLimitDictType",
    dictQueryById: "zyd-common/dictType/queryByIdDictType",
    dictSave: "zyd-common/dictType/insertDictType",
    updateDictType: "zyd-common/dictType/updateDictType",
    dictDelete: "zyd-common/dictType/deleteDictType",
    getDictMap2: "zyd-common/dictType/getDictMap",
    dictGetDictValue: "zyd-common/dictValue/queryAllDictValue",
    dictQueryDictValue: "zyd-common/dictValue/queryByIdDictValue",
    dictSaveDictValue: "zyd-common/dictValue/insertDictValue",
    updateDictValue: "zyd-common/dictValue/updateDictValue",
    dictDeleteDictValue: "zyd-common/dictValue/deleteByIdDictValue",
    getDictMap: "zyd-common/dictValue/queryByIdDictTypeValue",
    customColList: "zyd-common/customcol/customColList",
    save: "zyd-common/customcol/save",
}

let sys = {
    getMenus: "user/getMenus", // 用户权限菜单
    menuTreeData2: "menu/treeData2", // 菜单栏数据
    menuTreeData: "menu/treeData", // 菜单栏数据
    menuQueryById: "menu/queryById", // 单个菜单查询
    menuSave: "menu/save", // 单个菜单新增
    menuDelete: "menu/delete", // 单个菜单删除
    dataRuleQuery: "dataRule/queryById", // 数据规则查询
    dataRuleSave: "dataRule/save", // 数据规则保存
    dataRuleList: "dataRule/list", // 数据规则列表
    dataRuleDelete: "dataRule/delete", // 数据规则删除
    dataRuleTreeData: "dataRule/treeData", // 数据规则删除
    officeTreeData: "office/treeData", // 机构管理查询
    officeQueryById: "office/queryById",
    officeSave: "office/save",
    officeDelete: "office/delete",
    roleAssign: "role/assign",
    roleOutrole: "role/outrole",
    roleAssignrole: "role/assignrole",
    roleList: "role/list",
    roleDelete: "role/delete",
    roleQueryById: "role/queryById",
    roleSave: "role/save",
    userQueryById: "user/queryById",
    userList: "user/list",
    userDelete: "user/delete",
    userSave: "user/save",
    userSaveInfo: "user/saveInfo",
    userSavePwd: "user/savePwd",
    dataMine: "log/data/mine",
    postList: "post/list",
    postDelete: "post/delete",
    postQueryById: "post/queryById",
    postSave: "post/save",
    areaTreeData: "area/treeData",
    areaDelete: "area/delete",
    areaQueryById: "area/queryById",
    areaSave: "area/save",
    logList: "zyd-common/clickHouse/clickhouseTest",
}

// 订单
let order = {
    getSysArea: '/ming-en/sysarea/getSysArea',
    getMingenHospitalByCityId: '/ming-en/mingenhospital/getMingenHospitalByCityId',
    getAllMingenDepartment: '/ming-en/mingendepartment/getAllMingenDepartment',
    sysGetMingEnOrder: '/ming-en/mingenorder/sysGetMingEnOrder',
    contact: '/ming-en/mingencontact/list',
    hospitalList: '/ming-en/mingenhospital/list',
    hospitalRemove: '/ming-en/mingenhospital/removeById',
    hospitalSave: '/ming-en/mingenhospital/save',
    hospitalUpdate: '/ming-en/mingenhospital/updateById',
    departmentList: '/ming-en/mingendepartment/list',
    departmentRemove: '/ming-en/mingendepartment/removeById',
    departmentSave: '/ming-en/mingendepartment/save',
    departmentUpdate: '/ming-en/mingendepartment/updateById',
    optionalList: '/ming-en/mingendepartmentoptional/list',
    optionalRemove: '/ming-en/mingendepartmentoptional/removeById',
    optionalSave: '/ming-en/mingendepartmentoptional/save',
    optionalUpdate: '/ming-en/mingendepartmentoptional/updateById',
    requiredList: '/ming-en/mingendepartmentrequired/list',
    requiredRemove: '/ming-en/mingendepartmentrequired/removeById',
    requiredSave: '/ming-en/mingendepartmentrequired/save',
    requiredUpdate: '/ming-en/mingendepartmentrequired/updateById',
    returnPayOrder: '/ming-en/mingenorder/returnPayOrder',
    updateOrder: '/ming-en/mingenorder/updateOrder',
}
for (var s in sys) {
    sys[s] = 'sys/' + sys[s];
}


export default {
    auth,
    sys,
    order,
}
