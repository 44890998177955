/**
 * URL地址
 * @param {*} s
 */
export function isURL (s) {
  return /^http[s]?:\/\/.*/.test(s)
}

/**
 * 邮箱
 * @param {*} s
 */
export function isEmail (s) {
  return /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+((.[a-zA-Z0-9_-]{2,3}){1,2})$/.test(s)
}

/**
 * 手机号码
 * @param {*} s
 */
export function isMobile (s) {
  return /^[1][3,4,5,6,7,8,9][0-9]{9}$/.test(s)
}

/**
 * 电话号码
 * @param {*} s
 */
export function isPhone (s) {
  return /^([0-9]{3,4}-)?[0-9]{7,8}$/.test(s)
}

/*
 * 最少8-20字符，至少1个大写字母，1个小写字母，1个数字和1个特殊字符
 */
export function isPwd (value) {
  return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&]{8,20}/.test(value)
}

/**
 * 手机验证码
 * @param {*} s
 */
export function isMobileCode (s) {
  return /^\d{6}$/.test(s)
}

/**
 * 价格
 * @param {*} s
 */
export function isPrice (s) {
  return /^0\.([1-9]|\d[1-9])$|^[1-9]\d{0,8}\.\d{0,2}$|^[1-9]\d{0,8}$/.test(s)
  // return /(^[1-9]\d*(\.\d{1,2})?$)|(^0(\.\d{1,2})?$)/.test(s)
}

/*
 * 只能输入[0-9]数字
 * 只能输入[0-9]数字
 */
export function isDigits (value) {
  return /^\d+$/.test(value)
}

export default {isURL, isEmail, isMobile, isPhone, isPwd, isMobileCode, isDigits, isPrice}
